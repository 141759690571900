import { AddNewButton } from "../common/AddNewButton";
import { VerticalCard } from "../common/Card";
import { EditorHeader } from "../common/EditorHeader";
import { RemoveItemButton } from "../common/RemoveItemButton";
import { useRef, useState } from "react";
import { SingleDropdown } from "../attributes/Dropdown";
import { useColumnFormats } from "../hooks/useColumnFormats";
import {
  useExportFormat,
  useExportFormatDownload,
  useExportFormats,
  useExportTab,
} from "../hooks/useExportFormats";
import { UID } from "../../types/uid";
import { TextInput } from "../attributes/Input";
import { IconButton } from "monday-ui-react-core";
import { FileXls } from "@phosphor-icons/react";
import { downloadExcelWorkbook } from "../util/exportFileUtil";
import { FilterUnion } from "../common/FilterUnion";
import { EditorPage } from "../../types/editorPage";
import { ScrollButton } from "../common/ScrollButton";

const ExportFormatOptions = ({
  className,
  exportFormatId,
  tabId,
}: {
  className?: string;
  exportFormatId: UID;
  tabId: UID;
}) => {
  const { columnFormatOptions } = useColumnFormats();
  const { columnFormatId, setColumnFormatId, name, filterUnionId } =
    useExportTab(tabId);

  return (
    <div className={`${className} w-full flex flex-col p-2`}>
      <div className="font-semibold">{name}</div>
      <div>
        Column specification
        <SingleDropdown
          possibleItems={columnFormatOptions}
          selectedId={columnFormatId}
          className="w-fit"
          onChange={(columnFormatId) => setColumnFormatId(columnFormatId)}
        />
      </div>
      <div className="flex flex-col w-full ">
        Applicable data
        <FilterUnion
          filterUnionId={filterUnionId}
          editorPages={[EditorPage.Offers, EditorPage.Touchpoints]}
        />
      </div>
    </div>
  );
};

const ExportFormatTab = ({
  className,
  tabId,
  isSelected,
  onSelect,
  onRemove,
}: {
  className?: string;
  tabId: UID;
  isSelected: boolean;
  onSelect: (tabId: UID) => void;
  onRemove: (tabId: UID) => void;
}) => {
  const { name, rename } = useExportTab(tabId);

  return (
    <div
      key={tabId}
      className={`${className} flex items-center w-full gap-1
    border px-2 py-1 rounded-md
    ${isSelected ? "bg-blue-100" : "bg-slate-50"}`}
      onClick={() => {
        onSelect(tabId);
      }}
    >
      <TextInput initialValue={name} onChange={(newName) => rename(newName)} />
      <RemoveItemButton onRemove={() => onRemove(tabId)} />
    </div>
  );
};

const ExportFormat = ({
  className,
  exportFormatId,
}: {
  className?: string;
  exportFormatId: UID;
}) => {
  const { tabIds, name, rename, remove, addTab, removeTab } =
    useExportFormat(exportFormatId);

  const [selectedTabId, setSelectedTabId] = useState(
    tabIds.length > 0 ? tabIds[tabIds.length - 1] : undefined,
  );

  const { computeDownload } = useExportFormatDownload(exportFormatId);
  const exportFilename = name.replaceAll(" ", "_") || "export";

  return (
    <VerticalCard
      className={`${className} col-span-12 `}
      header={
        <div className="flex justify-between">
          <TextInput
            initialValue={name || ""}
            onChange={(newVal) => rename(newVal)}
            className="w-full"
          />
          <RemoveItemButton onRemove={() => remove()} />
          <IconButton
            icon={() => <FileXls size={24} />}
            size={IconButton.sizes.SMALL}
            color={IconButton.colors.POSITIVE}
            kind={IconButton.kinds.SECONDARY}
            onClick={() =>
              downloadExcelWorkbook(exportFilename, computeDownload(), true)
            }
          />
        </div>
      }
      bodyClassName="flex h-fit "
    >
      <div className="w-64 p-2 flex flex-col gap-2 bg-slate-100 h-full min-h-fit">
        <div className="">
          Tabs{" "}
          <AddNewButton
            onSelect={() => {
              addTab();
            }}
          />
        </div>
        {tabIds.map((tabId: UID) => (
          <ExportFormatTab
            key={tabId}
            tabId={tabId}
            isSelected={tabId === selectedTabId}
            onSelect={() => setSelectedTabId(tabId)}
            onRemove={() => {
              const isSelected = tabId === selectedTabId;
              if (isSelected) {
                const otherTab = tabIds
                  .slice()
                  .reverse()
                  .find((id) => id !== tabId);
                setSelectedTabId(otherTab);
              }
              removeTab(tabId);
            }}
          />
        ))}
      </div>
      <div className="w-full">
        {selectedTabId ? (
          <ExportFormatOptions
            exportFormatId={exportFormatId}
            tabId={selectedTabId}
          />
        ) : (
          <div className="p-2">Create a tab to get started</div>
        )}
      </div>
    </VerticalCard>
  );
};

const ExportsEditor = ({ className }: { className?: string }) => {
  const { exportFormatIds, create } = useExportFormats();
  const contentEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      className={`${className} w-full overflow-y-scroll pb-48`}
    >
      <EditorHeader
        className="p-4"
        title="Export"
        subtitle="Specify data used in exports"
      ></EditorHeader>
      <div className="flex gap-2">
        <AddNewButton
          title="New format"
          className="pl-4 pb-4"
          onSelect={() => create()}
        />
        <ScrollButton
          containerRef={containerRef}
          contentEndRef={contentEndRef}
        />
      </div>
      <div className="grid grid-cols-12 px-4 gap-2">
        {exportFormatIds.map((exportFormatId) => (
          <ExportFormat key={exportFormatId} exportFormatId={exportFormatId} />
        ))}
        <div ref={contentEndRef} />
      </div>
    </div>
  );
};

export { ExportsEditor };
