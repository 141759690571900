import { CaretDoubleDown } from "@phosphor-icons/react";
import { IconButton } from "monday-ui-react-core";

const ScrollButton = ({
  className,
  containerRef,
  contentEndRef,
}: {
  className?: string;
  containerRef: React.RefObject<HTMLDivElement>;
  contentEndRef: React.RefObject<HTMLDivElement>;
  title?: string;
}) => {
  return (
    <IconButton
      icon={CaretDoubleDown}
      size={IconButton.sizes.XS}
      className={`${className} hover:text-blue-400`}
      tooltipContent="Scroll down"
      tooltipProps={{ showDelay: 1000 }}
      onClick={() => {
        containerRef.current?.scrollTo({
          behavior: "smooth",
          top: contentEndRef.current?.offsetTop || 0,
        });
      }}
    />
  );
};

export { ScrollButton };
