import { VerticalCard } from "../common/Card";
import { EditorHeader } from "../common/EditorHeader";
import { AddNewButton } from "../common/AddNewButton";
import { UID } from "../../types/uid";
import { Copy } from "@phosphor-icons/react";
import { IconButton } from "monday-ui-react-core";
import { TextInput } from "../attributes/Input";
import { RemoveItemButton } from "../common/RemoveItemButton";
import { useTarget, useTargets } from "../hooks/useTargets";
import { FormulaBuilder } from "../common/FormulaBuilder";
import { EditorPage } from "../../types/editorPage";
import { SpreadMethodType } from "../../types/formula";
import { TargetPreviewLookup, useRecordPreviews } from "../hooks/useRecords";
import { useRef, useState } from "react";
import { RefreshButton } from "../common/RefreshButton";
import { FilterUnion } from "../common/FilterUnion";
import { ScrollButton } from "../common/ScrollButton";

const TargetOfferPreview = ({
  className,
  offerName,
  builtURLs,
}: {
  className?: string;
  offerName: string;
  builtURLs: string[];
}) => {
  return (
    <div className={`${className} grid grid-cols-12`}>
      <div className="col-span-4 text-sm">{offerName}</div>
      <div className="col-span-8">
        {builtURLs ? (
          builtURLs.map((url) => (
            <ul className="flex items-center gap-0.5" key={url}>
              <li>
                <a
                  href={url}
                  className="underline break-all"
                  target="_blank"
                  rel="noreferrer"
                >
                  {url}
                </a>
              </li>
            </ul>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const Target = ({
  className,
  targetId,
}: {
  className?: string;
  targetId: UID;
}) => {
  const { name, formulaId, filterUnionId, duplicate, rename, remove } =
    useTarget(targetId);

  const { computeTargetPreviewLookup } = useRecordPreviews();
  const [targetPreviewLookup, setTargetPreviewLookup] =
    useState<TargetPreviewLookup>({});

  const targetPreview =
    targetId in targetPreviewLookup ? targetPreviewLookup[targetId] : {};

  const filteredOfferIds = Object.keys(targetPreview);

  return (
    <VerticalCard
      className={`${className} h-fit`}
      header={
        <div className="flex items-center">
          {/* <DotsSixVertical size={20} /> */}
          <TextInput
            initialValue={name}
            className="w-full"
            onChange={(newName) => rename(newName)}
          />
          <IconButton icon={Copy} onClick={() => duplicate()} />
          <RemoveItemButton onRemove={() => remove()} />
        </div>
      }
      bodyClassName="p-2"
    >
      <div>
        Applicable offers
        <FilterUnion
          filterUnionId={filterUnionId}
          editorPages={[EditorPage.Offers]}
        />
        <FormulaBuilder
          className="pt-2"
          title="Build URL"
          formulaId={formulaId}
          editorPage={EditorPage.Offers}
          defaultSpreadMethod={SpreadMethodType.ForEach}
        />
        <div className="text-xs">
          Tip: If using select attributes, be sure to include a URL Term in the
          selects editor
        </div>
        <div className="flex">
          Preview
          <RefreshButton
            onRefresh={() =>
              setTargetPreviewLookup(computeTargetPreviewLookup())
            }
          />
        </div>
        <div className="bg-slate-100 text-slate-600 text-xs ">
          {filteredOfferIds
            .filter((offerId: UID) => offerId in targetPreview)
            .map((offerId: UID, idx: number) => (
              <TargetOfferPreview
                key={offerId}
                offerName={targetPreview[offerId].offerName}
                builtURLs={Array.from(targetPreview[offerId].urls)}
                className={`px-2 ${
                  idx % 2 === 0 ? "bg-slate-100" : "bg-slate-200"
                }`}
              />
            ))}
        </div>
      </div>
    </VerticalCard>
  );
};

const TargetsEditor = ({ className }: { className?: string }) => {
  const { targetIds, create } = useTargets();

  const contentEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      className={`${className} w-full overflow-y-scroll no-scrollbar pb-48`}
    >
      <EditorHeader
        className="p-4"
        title="Links"
        subtitle="Create URLs for online targets"
      >
        <div className="flex gap-2">
          <AddNewButton onSelect={() => create()} />
          <ScrollButton
            containerRef={containerRef}
            contentEndRef={contentEndRef}
          />
        </div>
      </EditorHeader>
      <div
        className={`${className} w-full grid grid-cols-12 gap-4 justify-center px-4`}
      >
        {targetIds.map((targetId: UID) => (
          <Target key={targetId} className="col-span-12" targetId={targetId} />
        ))}
        <div ref={contentEndRef} />
      </div>
    </div>
  );
};

export { TargetsEditor };
