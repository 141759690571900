import { VerticalCard } from "../common/Card";
import { TextInput } from "../attributes/Input";
import { EditorHeader } from "../common/EditorHeader";
import { AddNewButton } from "../common/AddNewButton";
import { CaretDoubleDown, Link, Star, X } from "@phosphor-icons/react";
import { Button, IconButton } from "monday-ui-react-core";
import { useSelect, useSelectRow, useSelects } from "../hooks/useSelects";
import { UID } from "../../types/uid";
import { RemoveItemButton } from "../common/RemoveItemButton";
import { Toggle } from "../common/Toggle";
import { useDebug } from "../hooks/useDebug";
import { useRef } from "react";
import { ScrollButton } from "../common/ScrollButton";

const SelectsCardRow = ({
  className,
  selectId,
  rowId,
  usesURL,
  usesUTM,
  isEvenRow,
}: {
  className?: string;
  selectId: UID;
  rowId: UID;
  usesURL: boolean;
  usesUTM: boolean;
  isEvenRow: boolean;
}) => {
  const { addRow } = useSelect(selectId);
  const numColumns = 1 + (usesURL ? 1 : 0) + (usesUTM ? 1 : 0);
  const colSpanClassName = `col-span-${12 / numColumns}`;

  const { utmTerm, urlTerm, name, editName, editUrl, editUtm, remove } =
    useSelectRow(selectId, rowId);

  return (
    <div
      className={`${className} flex items-center hover:bg-zinc-100 focus-within:bg-zinc-100
    ${isEvenRow ? "bg-slate-100" : ""} border-t pl-4`}
    >
      {/* <DotsSixVertical /> */}
      <div className={`grid grid-cols-12 w-full gap-2`}>
        <TextInput
          className={`${colSpanClassName} w-full `}
          initialValue={name}
          onChange={(newValue) => editName(newValue)}
          onEnterPress={() => numColumns === 1 && addRow()}
        />
        {usesURL && (
          <TextInput
            className={`${colSpanClassName} w-full`}
            initialValue={urlTerm}
            onChange={(newValue) => editUrl(newValue)}
            onEnterPress={() => numColumns === 2 && addRow()}
          />
        )}
        {usesUTM && (
          <TextInput
            className={`${colSpanClassName} w-full`}
            initialValue={utmTerm}
            onChange={(newValue) => editUtm(newValue)}
            onEnterPress={() =>
              ((numColumns === 2 && !usesURL) || numColumns === 3) && addRow()
            }
          />
        )}
      </div>
      <div className="w-fit">
        <IconButton
          icon={X}
          size={IconButton.sizes.XS}
          className="hover:text-red-400"
          onClick={() => remove()}
        />
      </div>
    </div>
  );
};

const SelectsCard = ({
  className,
  selectId,
}: {
  className?: string;
  selectId: UID;
}) => {
  const {
    title,
    usesURL,
    usesUTM,
    rename,
    remove,
    toggleURL,
    toggleUTM,
    rows,
    addRow,
  } = useSelect(selectId);

  const numColumns = 1 + (usesURL ? 1 : 0) + (usesUTM ? 1 : 0);
  const colSpanClassName = `col-span-${12 / numColumns}`;

  return (
    <VerticalCard
      className={`${className} ${
        numColumns === 1
          ? "col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-3 3xl:col-span-3"
          : numColumns === 2
          ? "col-span-12 3xl:col-span-6  "
          : "col-span-12 4xl:col-span-9 "
      } h-fit`}
      headerClassName="p-0.5 pr-1"
      header={
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-0.5">
            {/* <DotsSixVertical /> */}
            <TextInput
              initialValue={title}
              onChange={(value: string) => rename(value)}
              className="m-0.5 -mt-[0.5px] w-full"
            />
            <Toggle
              icon={Link}
              isSelected={usesURL}
              tooltip="URL Values"
              onToggle={() => {
                toggleURL(!usesURL);
              }}
            />
            <Toggle
              icon={Star}
              isSelected={usesUTM}
              tooltip="UTM Values"
              onToggle={() => {
                toggleUTM(!usesUTM);
              }}
            />
            <AddNewButton
              className="mx-1 mt-0.5"
              title="Add row"
              onSelect={() => addRow()}
            />
          </div>
          <RemoveItemButton onRemove={() => remove()} />
        </div>
      }
    >
      <div className={`grid grid-cols-12 ${numColumns > 1 ? "pr-5 pl-4" : ""}`}>
        <div
          className={`${colSpanClassName} text-xs text-slate-500 text-center`}
        >
          Friendly Value
        </div>
        {usesURL && (
          <div
            className={`${colSpanClassName} flex items-center justify-center gap-1 text-xs text-slate-500 text-center`}
          >
            <Link />
            URL Term
          </div>
        )}
        {usesUTM && (
          <div
            className={`${colSpanClassName} flex items-center justify-center gap-1 text-xs text-slate-500 text-center`}
          >
            <Star />
            UTM Term
          </div>
        )}
      </div>
      <div>
        {rows.map((rowId, idx) => (
          <SelectsCardRow
            key={rowId}
            selectId={selectId}
            rowId={rowId}
            usesURL={usesURL}
            usesUTM={usesUTM}
            isEvenRow={idx % 2 === 1}
            className={idx === rows.length - 1 ? "pb-1" : ""}
          />
        ))}
      </div>
    </VerticalCard>
  );
};

const SelectsEditor = ({ className }: { className?: string }) => {
  const { selectIds, create } = useSelects();
  const contentEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      className={`${className} w-full overflow-y-scroll no-scrollbar pb-48`}
    >
      <EditorHeader
        className="p-4"
        title="Selects"
        subtitle="Define values for single-select and multi-selects "
      >
        <div className="flex gap-2">
          <AddNewButton onSelect={() => create()} />
          <ScrollButton
            containerRef={containerRef}
            contentEndRef={contentEndRef}
          />
        </div>
      </EditorHeader>
      <div className="w-full grid grid-cols-12 gap-4 justify-center px-4">
        {selectIds.map((selectId: string) => (
          <SelectsCard key={selectId} selectId={selectId} className="" />
        ))}
        <div ref={contentEndRef} />
      </div>
    </div>
  );
};

export { SelectsEditor };
